.search-container-raffle-detail {
    background-size: auto 100%;
    background: unset;
    width: 100%;
    padding-top: 50px;
    background: transparent url('../images/Rectangle 49.png') 0% 0% no-repeat padding-box;
    /* z-index: 9; */
}

.detail-page-collection-name {
    letter-spacing: 0px;
    color: #FB9328;
    font-size: 15px;
}

.header-tabs .nav .nav-link.active {
    background-color: #fff;
    border-radius: 20px 20px 0px 0px;
}

.tab-pane .card {
    border-radius: 0px 20px 20px 20px;
}

.search-container-raffle-detail .raffle-image {
    width: 300px;
    height: 300px;
    border-radius: 30px;
    border: 1px solid #3f4254;
}

.no-of-ticket {
    width: 20%;
}

.pointer {
    cursor: pointer;
}

.raffle-detail-label {
    text-align: left;
    color: #776D96;
    font-size: 14px;
}

.raffle-detail-text {
    color: #FFFFFF;
    text-align: left;
    font-size: 20px;
    text-transform: capitalize;
}

.raffle-detail-card .card-body {
    /* padding-top: 40px; */
    background: #181426 !important;
    border-radius: 30px;
    border: 1px solid #3f4254;
}

.raffle-detail-card {
    min-height: 300px;
    max-height: 300px;
}

.back-arrow {
    background: #2B2734;
    border-radius: 50px;
    box-shadow: 0px 50px 8;
    width: 50px;
    padding: 15px;
}

.back-arrow img {
    width: 18px;
    height: 14px;
}

.raffle-details-table-card .card-body {
    border-radius: 20px 20px 20px 20px;
    background: #181426 !important;
}

.raffle-details-table-card .card-body table thead {
    border-bottom: 4px solid #0C0A13;
}

.raffle-details-table-card .card-body table thead th {
    font-size: 17px;
}

.raffle-details-table-card .card-body table thead td {
    font-size: 17px;
}

.raffle-details-table-card td:not(:last-child),
.raffle-details-table-card th:not(:last-child) {
    border-right: 4px solid #0C0A13;
}

.text-yellow {
    color: #FB9328 !important;
    font-weight: 500;
}

.slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 50px;
}


/*Switch*/

.switch {
    position: relative;
    width: 430px;
    height: 50px;
    display: block;
    margin: auto;
    margin-bottom: auto;
}

.switch input {
    display: none;
}

.slider.round {
    border-radius: 50px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #181426;
    -webkit-transition: .4s;
    transition: .4s;
}


/*Moving SLider Section*/

.slider::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 53%;
    left: 0;
    bottom: 0;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50px;
}


/*Slider Text*/

.slider_text {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    letter-spacing: 0.75px;
}

.slider_text>span {
    color: #fff;
    font-size: 18px;
    width: 50%;
    display: block;
    float: left;
    -webkit-transition: .4s;
    transition: .4s;
}


/*Changes on Slide*/

input:checked+.slider::before {
    -webkit-transform: translateX(200px);
    -ms-transform: translateX(200px);
    transform: translateX(200px);
}

input:checked+.slider .off {
    color: #514A66;
}

input:checked+.slider .on {
    color: #fff;
}

.slider .slider_text .on {
    color: #514A66;
}

.btn-raffle-detail {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 70px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 15px;
}

.ticket-price-box {
    background: #2B2543 0% 0% no-repeat padding-box;
    border-radius: 45px;
    border: unset;
    padding: 12px;
}

.participants-section {
    margin-top: 70px;
    margin-bottom: 10px;
}

.participant-table {
    max-height: 500px;
    overflow-y: scroll;
}


/* width */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #0C0A13;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #1097CD;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media only screen and (max-width: 768px) {
    .raffle-detail-card {
        margin-top: 20px;
        margin-bottom: 60px;
    }
    .winner-data {
        margin-top: 200px;
    }
    .form-control.form-control-solid {
        background-color: #fff;
        color: #000;
    }
    .ticket-price-box {
        width: 80px
    }
    .participants-section {
        margin-top: 150px;
    }
}