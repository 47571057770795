@import url(https://fonts.cdnfonts.com/css/clash-display);
.header {
    left: 0px !important;
    /* z-index: 10000; */
    /* background: #fff; */
    background: transparent linear-gradient(92deg, #130F1D 0%, #160F1E 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 50px 80px #0000004D;
    border: 0.5px solid #3E3944;
    border-radius: 0px 0px 50px 50px;
    opacity: 1;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
}

.create-dao-btn {
    padding: 15px 25px 15px 25px !important;
    /* background: transparent linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    color: #fff;
    border-radius: 50px;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    font-size: 15px;
}

.create-dao-btn i {
    color: #fff;
    font-weight: 600;
}

.connect-wallet-btn {
    padding: 10px 30px 10px 30px !important;
    /* background: transparent linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    color: #FFFFFF !important;
    font-size: 15px !important;
    /* background-color: #131627 !important; */
    height: unset !important;
    border-radius: 50px !important;
    background: #302A47 !important;
    height: 50px !important;
}

.connect-wallet-btn:hover {
    color: #186AB7;
}

.create-dao-btn:hover {
    color: #fff;
}

.card {
    border-radius: 30px;
}

.card .card-header {
    border-radius: 30px;
}

.user_avator {
    width: 50px;
    background: #302A47;
    border-radius: 25px;
    padding: 5px;
    cursor: pointer;
}

.form-label {
    color: #fff;
}

.tps {
    color: #FB9328;
    position: absolute;
    top: 5px;
    text-align: center;
    left: 42%;
    font-weight: 500;
}

@media only screen and (max-width: 768px) {
    .header {
        padding-top: 50px;
        padding-bottom: 35px;
    }
    .connect-wallet-btn {
        height: 45px !important;
        font-size: 12px !important;
        padding: 8px 16px 8px 16px !important;
    }
    .tps {
        left: 30%;
    }
}

.float-container {
    position: fixed;
    bottom: 0%;
    right: 0;
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: flex-end;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 15px;
    padding: 7px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
}

.float-container a {
    color: #fff;
}
.filter-home {
    float: right;
    margin-bottom: 15px;
}

.overflow {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.border {
    border: 1px dashed #0095e8 !important;
}

.raffle-home-card {
    border: 1px solid #1e439526
}

.raffle-home-card .card-body {
    background: #181426 !important;
    border-radius: 30px;
    border: 1px solid #3f4254;
    padding: unset;
}

#hero_section {
    width: 100%;
    display: inline-block;
    /* background: #12101000 linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    background: #2a3042;
    /* height: 300px; */
    top: 0px;
    left: 0px;
    /* width: 1920px; */
    height: 400px;
    /* UI Properties */
    background: transparent url("/static/media/Rectangle 49.a44806e9.png") 0% 0% no-repeat padding-box;
    /* opacity: 0.14; */
}

.search-container-box {
    background-color: #181426 !important;
    margin: auto;
    width: 50%;
    border-radius: 50px !important;
    border: 1px solid #3f4254;
}

.card .card-header {
    background-color: #3f4254;
    border-color: unset;
}

.drop {
    background: #181426;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    min-height: 138px;
    max-height: 200px;
    overflow: scroll;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
    transition: .4s height;
    width: 43%;
    margin: auto;
    position: relative;
    top: -17px;
    text-align: left;
}

.search-input-container {
    /* padding-top: 26%; */
}

.drop li {
    font-size: 13px;
    padding: 10px 10px;
    list-style: none;
    border-top: 1px solid #3f4254;
    cursor: pointer;
}

.search-filer-img {
    width: 35px;
    margin-right: 10px;
    border-radius: 4px;
}

.heading-1 {
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 45px;
    font-weight: 400;
}

.heading-2 {
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 20px;
    font-weight: 400;
}

.flame-img {
    width: 35px;
    height: 35px;
}

.recent-raffle-text {
    font-size: 25px;
}

.raffle-img-on-card img {
    width: 268px;
    height: 255px;
    margin-top: 10px;
    /* margin-bottom: 16px; */
    border-radius: 20px;
}

.collection-name {
    letter-spacing: 0px;
    color: #FB9328;
    font-size: 15px;
    padding-left: 20px;
}

.collection-img {
    width: 19px;
    height: 19px;
}

.raffle-name {
    padding-left: 20px;
    font-size: 20px;
    text-transform: capitalize;
}

.ticket-container {
    background: #25232E 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    margin: 10px;
}

.ticket-sold {
    font-size: 16px;
    font-weight: 500;
}

.ticket-remain {
    font-size: 14px;
    font-weight: 400;
}

.ticket-price {
    font-size: 16px;
    font-weight: 500;
}

.view-raffle-btn {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 70px;
    margin: 10px;
    color: #0852A8;
    border: 1px solid #0852A8 !important;
    color: #fff;
}

.view-raffle-btn:hover {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    color: #fff;
}

.raffle-time {
    top: -20px;
    position: relative;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    -webkit-backdrop-filter: blur(34px);
            backdrop-filter: blur(34px);
    width: 70%;
    margin: auto;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-size: 12px !important;
}

.text-yellow {
    color: #FB9328;
}

.ticket-sold-label,
.ticket-price-label {
    color: #FB9328;
    font-size: 15px;
    font-weight: 500;
}

.verticle-line {
    border-left: 2px solid #3E3C46;
    height: 30px;
}

.float-right {
    float: right;
}

.sort-by-select {
    background-color: #181426 !important;
    border-color: #181426 !important;
    border-radius: 50px !important;
    font-size: 15px !important;
    padding: 10px 20px !important;
    width: 149px !important;
    color: #D9D9D9 !important;
}

.search-result-text {
    font-size: 20px;
}

.swal2-popup .swal2-content,
.swal2-popup .swal2-html-container {
    font-size: 1.5rem !important;
    text-transform: capitalize;
}

.swal2-styled.swal2-confirm {
    background-color: #7066e0;
}

.desk-top-view {
    display: block;
}

.mobile-view {
    display: none;
}

.header {
    display: flex;
}

@media only screen and (max-width: 768px) {
    /* .desk-top-view {
        display: none;
    }
    .mobile-view {
        display: block;
        font-size: 15px !important;
        text-align: center;
        margin-top: 150px;
    }
    .header {
        display: none;
    } */
    .search-container-box {
        margin-top: 20px;
        width: 100%;
        height: 70px;
    }
    .heading-1 {
        font-size: 30px;
    }
    .heading-2 {
        font-size: 16px;
    }
    .drop {
        width: 100%;
    }
}


/* .mobile-view img {
    width: 200px;
} */
.loader {
    width: 100px;
    height: 100px;
    position: relative;
    margin: auto;
    top: 45%;
}

.loader div {
    position: absolute;
    width: 35%;
    height: 35%;
    border-radius: 5px;
    -webkit-animation: load 2s infinite ease-in-out;
            animation: load 2s infinite ease-in-out;
}

.loader div:nth-of-type(1) {
    background-color: #1e4395;
}

.loader div:nth-of-type(2) {
    background-color: #231f20;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
}

.loader div:nth-of-type(3) {
    background-color: #1e4395;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.loader div:nth-of-type(4) {
    background-color: #231f20;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: translate(0%);
                transform: translate(0%);
        border-radius: 50%;
    }
    25% {
        -webkit-transform: translate(200%) rotate(45deg);
                transform: translate(200%) rotate(45deg);
        border-radius: 0%;
    }
    50% {
        -webkit-transform: translate(200%, 200%);
                transform: translate(200%, 200%);
        border-radius: 50%;
    }
    75% {
        -webkit-transform: translate(0, 200%) rotate(-45deg);
                transform: translate(0, 200%) rotate(-45deg);
        border-radius: 0%;
    }
    100% {
        -webkit-transform: translate(0%);
                transform: translate(0%);
        border-radius: 50%;
    }
}

@keyframes load {
    0% {
        -webkit-transform: translate(0%);
                transform: translate(0%);
        border-radius: 50%;
    }
    25% {
        -webkit-transform: translate(200%) rotate(45deg);
                transform: translate(200%) rotate(45deg);
        border-radius: 0%;
    }
    50% {
        -webkit-transform: translate(200%, 200%);
                transform: translate(200%, 200%);
        border-radius: 50%;
    }
    75% {
        -webkit-transform: translate(0, 200%) rotate(-45deg);
                transform: translate(0, 200%) rotate(-45deg);
        border-radius: 0%;
    }
    100% {
        -webkit-transform: translate(0%);
                transform: translate(0%);
        border-radius: 50%;
    }
}

.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f5ffe8;
    z-index: 9999999;
}


/* common */

.loader {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: linear-gradient(45deg, transparent, transparent 40%, #e5f403);
    -webkit-animation: animate 2s linear infinite;
            animation: animate 2s linear infinite;
}

.loader::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    bottom: 6px;
    right: 6px;
    background: #000;
    border-radius: 50%;
    z-index: 1000;
}

.loader::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: linear-gradient(45deg, transparent, transparent 40%, #e5f403);
    border-radius: 50%;
    -webkit-filter: blur(60px);
            filter: blur(60px);
    z-index: 1;
}

@-webkit-keyframes animate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-filter: hue-rotate(0deg);
                filter: hue-rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
        -webkit-filter: hue-rotate(360deg);
                filter: hue-rotate(360deg);
    }
}

@keyframes animate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-filter: hue-rotate(0deg);
                filter: hue-rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
        -webkit-filter: hue-rotate(360deg);
                filter: hue-rotate(360deg);
    }
}
.stepper.stepper-pills.stepper-column .stepper-wrapper {
    display: flex;
    align-items: center;
}

.form-control.form-control-solid {
    border-color: #338cd5;
}

#dao:checked+label,
#individual:checked+label {
    background-color: #dcfdfd;
}

.input-hidden {
    position: absolute;
    left: -9999px;
}

input[type=radio]:checked+label>img {
    border: 1px solid #fff;
    box-shadow: 0 0 3px 3px #172e69;
}


/* Stuff after this is only to make things more pretty */

input[type=radio]+label>img {
    border-radius: 20px;
    width: 150px;
    height: 150px;
    transition: 500ms all;
}

input[type=radio]:checked+label>img {
    -webkit-transform: rotateZ(-10deg) rotateX(10deg);
            transform: rotateZ(-10deg) rotateX(10deg);
}

.raffle-price-text {
    border-radius: 10px 0px 0px 10px;
}

.raffle-price-select {
    height: 46px;
    border-radius: 0px 10px 10px 0px;
    border-color: #338cd5 !important;
}

.card {
    background: #2a3042;
}

.form-select.form-select-solid {
    background-color: #222736;
    border-color: #222736;
    color: #fff;
}

.form-control.form-control-solid {
    background-color: #222736;
    color: #fff;
}

.btn-check:checked+.btn.btn-active-light-primary {
    background-color: #222736 !important;
}

.wl-token-name {
    word-break: break-all;
}
.search-container-raffle-detail {
    background-size: auto 100%;
    background: unset;
    width: 100%;
    padding-top: 50px;
    background: transparent url("/static/media/Rectangle 49.a44806e9.png") 0% 0% no-repeat padding-box;
    /* z-index: 9; */
}

.detail-page-collection-name {
    letter-spacing: 0px;
    color: #FB9328;
    font-size: 15px;
}

.header-tabs .nav .nav-link.active {
    background-color: #fff;
    border-radius: 20px 20px 0px 0px;
}

.tab-pane .card {
    border-radius: 0px 20px 20px 20px;
}

.search-container-raffle-detail .raffle-image {
    width: 300px;
    height: 300px;
    border-radius: 30px;
    border: 1px solid #3f4254;
}

.no-of-ticket {
    width: 20%;
}

.pointer {
    cursor: pointer;
}

.raffle-detail-label {
    text-align: left;
    color: #776D96;
    font-size: 14px;
}

.raffle-detail-text {
    color: #FFFFFF;
    text-align: left;
    font-size: 20px;
    text-transform: capitalize;
}

.raffle-detail-card .card-body {
    /* padding-top: 40px; */
    background: #181426 !important;
    border-radius: 30px;
    border: 1px solid #3f4254;
}

.raffle-detail-card {
    min-height: 300px;
    max-height: 300px;
}

.back-arrow {
    background: #2B2734;
    border-radius: 50px;
    box-shadow: 0px 50px 8;
    width: 50px;
    padding: 15px;
}

.back-arrow img {
    width: 18px;
    height: 14px;
}

.raffle-details-table-card .card-body {
    border-radius: 20px 20px 20px 20px;
    background: #181426 !important;
}

.raffle-details-table-card .card-body table thead {
    border-bottom: 4px solid #0C0A13;
}

.raffle-details-table-card .card-body table thead th {
    font-size: 17px;
}

.raffle-details-table-card .card-body table thead td {
    font-size: 17px;
}

.raffle-details-table-card td:not(:last-child),
.raffle-details-table-card th:not(:last-child) {
    border-right: 4px solid #0C0A13;
}

.text-yellow {
    color: #FB9328 !important;
    font-weight: 500;
}

.slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 50px;
}


/*Switch*/

.switch {
    position: relative;
    width: 430px;
    height: 50px;
    display: block;
    margin: auto;
    margin-bottom: auto;
}

.switch input {
    display: none;
}

.slider.round {
    border-radius: 50px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #181426;
    transition: .4s;
}


/*Moving SLider Section*/

.slider::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 53%;
    left: 0;
    bottom: 0;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    transition: .4s;
    border-radius: 50px;
}


/*Slider Text*/

.slider_text {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    letter-spacing: 0.75px;
}

.slider_text>span {
    color: #fff;
    font-size: 18px;
    width: 50%;
    display: block;
    float: left;
    transition: .4s;
}


/*Changes on Slide*/

input:checked+.slider::before {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
}

input:checked+.slider .off {
    color: #514A66;
}

input:checked+.slider .on {
    color: #fff;
}

.slider .slider_text .on {
    color: #514A66;
}

.btn-raffle-detail {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 70px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 15px;
}

.ticket-price-box {
    background: #2B2543 0% 0% no-repeat padding-box;
    border-radius: 45px;
    border: unset;
    padding: 12px;
}

.participants-section {
    margin-top: 70px;
    margin-bottom: 10px;
}

.participant-table {
    max-height: 500px;
    overflow-y: scroll;
}


/* width */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #0C0A13;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #1097CD;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media only screen and (max-width: 768px) {
    .raffle-detail-card {
        margin-top: 20px;
        margin-bottom: 60px;
    }
    .winner-data {
        margin-top: 200px;
    }
    .form-control.form-control-solid {
        background-color: #fff;
        color: #000;
    }
    .ticket-price-box {
        width: 80px
    }
    .participants-section {
        margin-top: 150px;
    }
}
.card {
    border: unset;
}

.my-account .card-body {
    background: #181426 !important;
    border-radius: 30px;
    border: 1px solid #3f4254;
}

.text-blue {
    color: #0095e8 !important;
}

.border {
    border: 1px dashed #929eab !important;
}

.table.table-row-dashed tr {
    border-bottom-color: #929eab;
}

.my-account-img {
    width: 150px;
    height: 150px;
}

.my-account-small-box {
    background: transparent linear-gradient(90deg, #302A47 0%, #302A4700 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #36C4C2;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.my-account-small-box-2 {
    background: transparent linear-gradient(90deg, #302A47 0%, #302A4700 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #FE5D46;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.my-account-small-box .label,
.my-account-small-box-2 .label {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0px;
    color: #776D96;
}

.my-account-small-box .text,
.my-account-small-box-2 .text {
    text-align: left;
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.slider-container-myaccount {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -60px;
}

.switch-myaccount {
    position: relative;
    width: 430px;
    height: 60px;
    display: block;
    margin: auto;
    margin-bottom: auto;
    border: 5px solid #000;
    border-radius: 25px;
}

.switch-myaccount .toggle_vat {
    visibility: hidden;
}

.my-account-table-card .card-body {
    border-radius: 20px 20px 20px 20px;
    background: #181426 !important;
}

.my-account-table-card .card-body table thead {
    border-bottom: 4px solid #0C0A13;
}

.my-account-table-card .card-body table thead th {
    font-size: 15px;
    color: #776D96;
}

.my-account-table-card .card-body table td {
    font-size: 17px;
}

.my-account-table-card td:not(:last-child),
.my-account-table-card th:not(:last-child) {
    border-right: 4px solid #0C0A13;
}

.btn-view-myaccount {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #fff;
}

.text-gray {}
.wallet-notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  padding: 12px 15px;
}

.wallet-notification-error {
  background: rgba(235, 55, 66, 0.7);
}

.wallet-notification-info {
  background: rgba(78, 68, 206, 0.7);
}

.wallet-notification-success {
  background: rgba(33, 229, 111, 0.7);
}

body {
    margin: 0;
    font-family: 'Clash Display', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0C0A13;
    /* background-image: url("images/bg.png"); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.wrapper {
    padding-left: unset;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    background: #2c2d30;
}

nav>div {
    display: flex;
}

nav>div>button {
    margin-left: 1rem;
}

@media (max-width: 400px) {
    nav>h1 {
        max-width: 90px;
    }
}

.wallet-adapter-button,
.wallet-adapter-modal-wrapper {
    font: inherit;
}

.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14vw, 1fr));
    grid-gap: 2rem;
    gap: 2rem;
    position: relative;
    max-width: 70vw;
    padding: 0 15vw;
}

.card-grid .card {
    display: inline-grid;
    place-content: center;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    padding: 0.25rem;
    border: 0.4rem solid white;
    border-radius: 1rem;
    text-align: center;
}

.card-grid .card img {
    max-width: 12rem;
    border-radius: 0.4rem;
}

.card-grid .card h3 {
    margin: 0.3rem 0;
    max-width: 12rem;
}

.card-grid .card button {
    display: inline-block;
    margin: 0.1rem;
    font-size: 1rem;
    font-family: inherit;
    color: inherit;
    background-color: #fff2;
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    border: 0.25px solid #fff4;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
}

.counter {
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
}


/* .toolbar {
    background: #f3f6f9;
} */
