.filter-home {
    float: right;
    margin-bottom: 15px;
}

.overflow {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.border {
    border: 1px dashed #0095e8 !important;
}

.raffle-home-card {
    border: 1px solid #1e439526
}

.raffle-home-card .card-body {
    background: #181426 !important;
    border-radius: 30px;
    border: 1px solid #3f4254;
    padding: unset;
}

#hero_section {
    width: 100%;
    display: inline-block;
    /* background: #12101000 linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    background: #2a3042;
    /* height: 300px; */
    top: 0px;
    left: 0px;
    /* width: 1920px; */
    height: 400px;
    /* UI Properties */
    background: transparent url('../images/Rectangle 49.png') 0% 0% no-repeat padding-box;
    /* opacity: 0.14; */
}

.search-container-box {
    background-color: #181426 !important;
    margin: auto;
    width: 50%;
    border-radius: 50px !important;
    border: 1px solid #3f4254;
}

.card .card-header {
    background-color: #3f4254;
    border-color: unset;
}

.drop {
    background: #181426;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    min-height: 138px;
    max-height: 200px;
    overflow: scroll;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
    transition: .4s height;
    width: 43%;
    margin: auto;
    position: relative;
    top: -17px;
    text-align: left;
}

.search-input-container {
    /* padding-top: 26%; */
}

.drop li {
    font-size: 13px;
    padding: 10px 10px;
    list-style: none;
    border-top: 1px solid #3f4254;
    cursor: pointer;
}

.search-filer-img {
    width: 35px;
    margin-right: 10px;
    border-radius: 4px;
}

.heading-1 {
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 45px;
    font-weight: 400;
}

.heading-2 {
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 20px;
    font-weight: 400;
}

.flame-img {
    width: 35px;
    height: 35px;
}

.recent-raffle-text {
    font-size: 25px;
}

.raffle-img-on-card img {
    width: 268px;
    height: 255px;
    margin-top: 10px;
    /* margin-bottom: 16px; */
    border-radius: 20px;
}

.collection-name {
    letter-spacing: 0px;
    color: #FB9328;
    font-size: 15px;
    padding-left: 20px;
}

.collection-img {
    width: 19px;
    height: 19px;
}

.raffle-name {
    padding-left: 20px;
    font-size: 20px;
    text-transform: capitalize;
}

.ticket-container {
    background: #25232E 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    margin: 10px;
}

.ticket-sold {
    font-size: 16px;
    font-weight: 500;
}

.ticket-remain {
    font-size: 14px;
    font-weight: 400;
}

.ticket-price {
    font-size: 16px;
    font-weight: 500;
}

.view-raffle-btn {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 70px;
    margin: 10px;
    color: #0852A8;
    border: 1px solid #0852A8 !important;
    color: #fff;
}

.view-raffle-btn:hover {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    color: #fff;
}

.raffle-time {
    top: -20px;
    position: relative;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(34px);
    width: 70%;
    margin: auto;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-size: 12px !important;
}

.text-yellow {
    color: #FB9328;
}

.ticket-sold-label,
.ticket-price-label {
    color: #FB9328;
    font-size: 15px;
    font-weight: 500;
}

.verticle-line {
    border-left: 2px solid #3E3C46;
    height: 30px;
}

.float-right {
    float: right;
}

.sort-by-select {
    background-color: #181426 !important;
    border-color: #181426 !important;
    border-radius: 50px !important;
    font-size: 15px !important;
    padding: 10px 20px !important;
    width: 149px !important;
    color: #D9D9D9 !important;
}

.search-result-text {
    font-size: 20px;
}

.swal2-popup .swal2-content,
.swal2-popup .swal2-html-container {
    font-size: 1.5rem !important;
    text-transform: capitalize;
}

.swal2-styled.swal2-confirm {
    background-color: #7066e0;
}

.desk-top-view {
    display: block;
}

.mobile-view {
    display: none;
}

.header {
    display: flex;
}

@media only screen and (max-width: 768px) {
    /* .desk-top-view {
        display: none;
    }
    .mobile-view {
        display: block;
        font-size: 15px !important;
        text-align: center;
        margin-top: 150px;
    }
    .header {
        display: none;
    } */
    .search-container-box {
        margin-top: 20px;
        width: 100%;
        height: 70px;
    }
    .heading-1 {
        font-size: 30px;
    }
    .heading-2 {
        font-size: 16px;
    }
    .drop {
        width: 100%;
    }
}


/* .mobile-view img {
    width: 200px;
} */