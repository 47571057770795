.header {
    left: 0px !important;
    /* z-index: 10000; */
    /* background: #fff; */
    background: transparent linear-gradient(92deg, #130F1D 0%, #160F1E 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 50px 80px #0000004D;
    border: 0.5px solid #3E3944;
    border-radius: 0px 0px 50px 50px;
    opacity: 1;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
}

.create-dao-btn {
    padding: 15px 25px 15px 25px !important;
    /* background: transparent linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    color: #fff;
    border-radius: 50px;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    font-size: 15px;
}

.create-dao-btn i {
    color: #fff;
    font-weight: 600;
}

.connect-wallet-btn {
    padding: 10px 30px 10px 30px !important;
    /* background: transparent linear-gradient( 270deg, #2898d5, #1d4496) 0 0 no-repeat padding-box; */
    color: #FFFFFF !important;
    font-size: 15px !important;
    /* background-color: #131627 !important; */
    height: unset !important;
    border-radius: 50px !important;
    background: #302A47 !important;
    height: 50px !important;
}

.connect-wallet-btn:hover {
    color: #186AB7;
}

.create-dao-btn:hover {
    color: #fff;
}

.card {
    border-radius: 30px;
}

.card .card-header {
    border-radius: 30px;
}

.user_avator {
    width: 50px;
    background: #302A47;
    border-radius: 25px;
    padding: 5px;
    cursor: pointer;
}

.form-label {
    color: #fff;
}

.tps {
    color: #FB9328;
    position: absolute;
    top: 5px;
    text-align: center;
    left: 42%;
    font-weight: 500;
}

@media only screen and (max-width: 768px) {
    .header {
        padding-top: 50px;
        padding-bottom: 35px;
    }
    .connect-wallet-btn {
        height: 45px !important;
        font-size: 12px !important;
        padding: 8px 16px 8px 16px !important;
    }
    .tps {
        left: 30%;
    }
}

.float-container {
    position: fixed;
    bottom: 0%;
    right: 0;
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: flex-end;
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 15px;
    padding: 7px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
}

.float-container a {
    color: #fff;
}