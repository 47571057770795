.stepper.stepper-pills.stepper-column .stepper-wrapper {
    display: flex;
    align-items: center;
}

.form-control.form-control-solid {
    border-color: #338cd5;
}

#dao:checked+label,
#individual:checked+label {
    background-color: #dcfdfd;
}

.input-hidden {
    position: absolute;
    left: -9999px;
}

input[type=radio]:checked+label>img {
    border: 1px solid #fff;
    box-shadow: 0 0 3px 3px #172e69;
}


/* Stuff after this is only to make things more pretty */

input[type=radio]+label>img {
    border-radius: 20px;
    width: 150px;
    height: 150px;
    transition: 500ms all;
}

input[type=radio]:checked+label>img {
    transform: rotateZ(-10deg) rotateX(10deg);
}

.raffle-price-text {
    border-radius: 10px 0px 0px 10px;
}

.raffle-price-select {
    height: 46px;
    border-radius: 0px 10px 10px 0px;
    border-color: #338cd5 !important;
}

.card {
    background: #2a3042;
}

.form-select.form-select-solid {
    background-color: #222736;
    border-color: #222736;
    color: #fff;
}

.form-control.form-control-solid {
    background-color: #222736;
    color: #fff;
}

.btn-check:checked+.btn.btn-active-light-primary {
    background-color: #222736 !important;
}

.wl-token-name {
    word-break: break-all;
}