.card {
    border: unset;
}

.my-account .card-body {
    background: #181426 !important;
    border-radius: 30px;
    border: 1px solid #3f4254;
}

.text-blue {
    color: #0095e8 !important;
}

.border {
    border: 1px dashed #929eab !important;
}

.table.table-row-dashed tr {
    border-bottom-color: #929eab;
}

.my-account-img {
    width: 150px;
    height: 150px;
}

.my-account-small-box {
    background: transparent linear-gradient(90deg, #302A47 0%, #302A4700 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #36C4C2;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.my-account-small-box-2 {
    background: transparent linear-gradient(90deg, #302A47 0%, #302A4700 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #FE5D46;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.my-account-small-box .label,
.my-account-small-box-2 .label {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0px;
    color: #776D96;
}

.my-account-small-box .text,
.my-account-small-box-2 .text {
    text-align: left;
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.slider-container-myaccount {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -60px;
}

.switch-myaccount {
    position: relative;
    width: 430px;
    height: 60px;
    display: block;
    margin: auto;
    margin-bottom: auto;
    border: 5px solid #000;
    border-radius: 25px;
}

.switch-myaccount .toggle_vat {
    visibility: hidden;
}

.my-account-table-card .card-body {
    border-radius: 20px 20px 20px 20px;
    background: #181426 !important;
}

.my-account-table-card .card-body table thead {
    border-bottom: 4px solid #0C0A13;
}

.my-account-table-card .card-body table thead th {
    font-size: 15px;
    color: #776D96;
}

.my-account-table-card .card-body table td {
    font-size: 17px;
}

.my-account-table-card td:not(:last-child),
.my-account-table-card th:not(:last-child) {
    border-right: 4px solid #0C0A13;
}

.btn-view-myaccount {
    background: transparent linear-gradient(180deg, #1097CD 0%, #053C9B 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #fff;
}

.text-gray {}